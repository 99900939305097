@font-face {
  font-family: 'icons';
  src:
    url('./icons.eot?2e004371c6120fbebca8c06ccf557f64#iefix')
      format('embedded-opentype'),
    url('./icons.woff?2e004371c6120fbebca8c06ccf557f64') format('woff'),
    url('./icons.woff2?2e004371c6120fbebca8c06ccf557f64') format('woff2');
}

i[class^='icon-']:before,
i[class*=' icon-']:before {
  font-family: icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-badge-star:before {
  content: '\f101';
}
.icon-caution:before {
  content: '\f102';
}
.icon-circle:before {
  content: '\f103';
}
.icon-close-large:before {
  content: '\f104';
}
.icon-comment:before {
  content: '\f105';
}
.icon-down-arrow:before {
  content: '\f106';
}
.icon-edit:before {
  content: '\f107';
}
.icon-flash-off:before {
  content: '\f108';
}
.icon-flash:before {
  content: '\f109';
}
.icon-info:before {
  content: '\f10a';
}
.icon-money-sign:before {
  content: '\f10b';
}
.icon-pin:before {
  content: '\f10c';
}
.icon-scan:before {
  content: '\f10d';
}
.icon-trash:before {
  content: '\f10e';
}
.icon-user-profile:before {
  content: '\f10f';
}
